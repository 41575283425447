/* =============================
*  NEW CALC INIT
================================ */
// SHORT CALC
$.getScript('/assets/js/calc.js', function () {

	if ($('[data-calc-mini]').length > 0) {
		new Calc({
			ID: '131',
			calculatorType: 'DefaultCalculator',
			format: 'html',
			environment: getEnvironment,
			options: {
				deafault: {
					deadlinesDefault: [96221],
					academicLevelDefault: 3,
					paperTypesDefault: 9,
					pagesDefault: 2
				}
			}
		});
	}
});
/* =============================
*  end NEW CALC INIT
================================ */

(function toggleListInit() {
  if (!document.querySelector('[data-page="frequently_asked_questions"]') && !document.querySelector('[data-page="money_back_guarantee"]')) return false;

  function toggle_list() {
    $('.toggle-list .toggle-content').addClass('hide');
    $(document).on('click', '.toggle-list .toggle-link', function(){
      if($(this).next().hasClass('hide')){ $(this).next().slideDown(500); }else{ $(this).next().slideUp(500); }
      $(this).parent().toggleClass('active');
      $(this).next().toggleClass('hide');
      return false;
    });
  }

  window.addEventListener('contentRendered', toggle_list);
})();


(function (window, document) {
  function init() {
    var element = document.getElementById('CommonPage');

    if (element) {
      var pageName = element.getAttribute('data-page');
      var siteId = element.getAttribute('data-site-id');
      var policies = element.getAttribute('data-policies');
      var policyId = element.getAttribute('data-policy-id');

      let widgetOptions = {
        siteId,
        pageName,
      };

      if (policies && policyId) {
        widgetOptions = { siteId, pageName, policies, policyId };
      }

      window.commonPagesWidget.widget(element, widgetOptions);
    }
  }

  if (typeof window.commonPagesWidget !== 'undefined') {
    init();
  } else {
    var script = document.createElement('script');
    script.src = '/assets/js/commonPagesWidget.min.js';
    script.async = true;
    script.onload = function () {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
})(window, document);



/**
 * Description: JS for
 * Version: 1.0.0
 * Last update: 2015/05/01
 * Author: UVOCorp
 *
 * Summary:
 *
 *	0. matchMedia() polyfill
 *		- Test a CSS media type/query in JS. Authors & copyright (c) 2012: Scott Jehl, Paul Irish, Nicholas Zakas. Dual MIT/BSD license
 *	1. Respond.js
 *		- min/max-width media query polyfill.
 *	2. Custom JS for
 *
 */



/* ==========================================================================
   2. Custom JS for
   ========================================================================== */




$(function() {
    //add class "selected" for item select

    //count pages
    $.fn.counts = function(options) {
        //defaultOptions
        options = $.extend({
            minusClass: 'pageskol-left',
            minimum: 1
        }, options);
        var inputCol = $(this);
        if ($(inputCol).val() <= options.minimum) {
            $('button[class=' + options.minusClass + ']').css({
                'opacity': '0.5',
                'cursor': 'default'
            });
        } else {
            $('button[class=' + options.minusClass + ']').css({
                'opacity': '1',
                'cursor': 'pointer'
            });
        }
        $(inputCol).parent().find('span').click(function() {
            if ($(this).hasClass(options.minusClass)) {
                if ($(inputCol).val() >= options.minimum) {
                    var count = parseInt($(inputCol).val()) -
                        1;
                    count = count < options.minimum ?
                        options.minimum : count;
                } else {
                    $(inputCol).val(options.minimum - 1);
                    var count = parseInt($(inputCol).val()) -
                        1;
                    count = count < options.minimum ?
                        options.minimum : count;
                }
                if ($(inputCol).val() <= (options.minimum +
                        1)) {
                    $('button[class=' + options.minusClass +
                        ']').css({
                        'opacity': '0.5',
                        'cursor': 'default'
                    });
                }
            } else {
                if ($(inputCol).val() >= options.minimum) {
                    var count = parseInt($(inputCol).val()) +
                        1;
                } else {
                    $(inputCol).val(options.minimum - 1);
                    var count = parseInt($(inputCol).val()) +
                        1;
                }
                if ($(inputCol).val() > (options.minimum -
                        1)) {
                    $('button[class=' + options.minusClass +
                        ']').css({
                        'opacity': '1',
                        'cursor': 'pointer'
                    })
                }
            }
            $(inputCol).val(count);
            $(inputCol).change();
            $(this).parent().next().find('span').html(275 *
                count);
            return false;
        });
        $(this).change(function(e) {
            var count = parseInt($(this).val())
            if (isNaN(count)) {
                count = 1;
                $(this).val(1);
            }
            $(this).parent().next().find('span').html(275 *
                count);
        });
        $(this).focusout(function(e) {
            var count = parseInt($(this).val())
            if (isNaN(count)) {
                count = 1;
                $(this).val(1);
            }
            $(this).parent().next().find('span').html(275 *
                count);
        });
        $(this).keydown(function(e) {
            if (e.which != 8 && e.which != 0 && (e.which <
                    37 || e.which > 57) && e.which != 96 &&
                e.which != 97 && e.which != 98 && e.which !=
                99 && e.which != 100 && e.which != 101 && e
                .which != 102 && e.which != 103 && e.which !=
                104 && e.which != 105) {
                return false;
            }
            var key, keyChar;
            if (!e) var e = window.e;
            if (e.keyCode) key = e.keyCode;
            else if (e.which) key = e.which;
            if (key == 8) {
                return true;
            }
            if (key == 38 || key == 39 || (e.which < 48 ||
                    e.which > 57) && e.which != 96 && e.which !=
                97 && e.which != 98 && e.which != 99 && e.which !=
                100 && e.which != 101 && e.which != 102 &&
                e.which != 103 && e.which != 104 && e.which !=
                105) {
                if (key == 40 || key == 37) {
                    if ($(this).val() >= options.minimum) {
                        var count = parseInt($(this).val()) -
                            1;
                        count = count < options.minimum ?
                            options.minimum : count;
                    } else {
                        $(this).val(options.minimum - 1);
                        keyChar = String.fromCharCode(key);
                        var count = parseInt($(this).val()) +
                            1;
                    }
                } else {
                    if ($(this).val() >= options.minimum) {
                        var count = parseInt($(this).val()) +
                            1;
                    } else {
                        $(this).val(options.minimum - 1);
                        var count = parseInt($(this).val()) +
                            1;
                    }
                }
                $(this).val(count);
                $(this).parent().next().find('span').html(
                    275 * count);
                if ($(inputCol).val() <= (options.minimum)) {
                    $('button[class=' + options.minusClass +
                        ']').css({
                        'opacity': '0.5',
                        'cursor': 'default'
                    });
                } else {
                    $('button[class=' + options.minusClass +
                        ']').css({
                        'opacity': '1',
                        'cursor': 'pointer'
                    })
                }
            }
        });
    }
    //counts
    $('#input-kol').counts();

    //toplink click
    $(window).scroll(function() {
        if ($(this).scrollTop() > 700) {
            $('.totop-link').fadeIn();
        } else {
            $('.totop-link').fadeOut();
        }
    });
    $('.totop-link').click(function() {
        $('body,html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });

    //toggle topmenu
    if ($(window).width() <= 980) {
        $('.toggle-menu').click(function() {
            $(this).toggleClass('active');
            $(".toggle").stop().slideToggle("slow");
        });
    } else {
        $(this).removeClass('active');
    }


    $('.show-hide').find('.hidden').hide();
    $('#inq-done-more').click(function() {
        if (!$(this).hasClass('less')) {
            $(this).parents('.show-hide').find('.hidden').slideDown(
                500);
            $(this).text('Read less').addClass('less');
        } else {
            $(this).parents('.show-hide').find('.hidden').slideUp(
                500);
            $(this).text('Read more').removeClass('less');
        }
    });
    var pl_panel = $('.plagcheckc aside#rightplagpanel').clone();
    $(pl_panel).removeAttr('id').appendTo('.plagcheckc').attr('id',
        'rightplagpanel_2');





    //tabs for testimonials
    $("#tabs").tabs({
        collapsible: true
    });

    $(".login-b2#trackOrder").wrap("<div class='box-submit'></div>");

});


$(function() {
    var wWidth = $(document).width();
    var $tel = $(".right-block-tel");

    appendMenuFunction();

    $(window).on('resize', function() {
        appendMenuFunction();
    });

    function appendMenuFunction() {
        wWidth = $(document).width();
        if (wWidth <= 980) {
            $tel.appendTo($(".nav-header"));
        } else {
            $tel.prependTo($(".wrapper"));
        }
    }

    /*home testimonials*/
    $('.navigation a').each(function() {
        if (this.href == location.href) $(this).addClass('active');
    });

    var testimonialsSlider = $("#testimonials-slider");

    if (testimonialsSlider.length > 0) {
        testimonialsSlider.owlCarousel({
            items: 2,
            autoplay: true,
            loop: true,
            nav: true,
            dots: false,
            navText: "",
            responsive: {
                768: {
                    items: 2,
                },
                0: {
                    items: 1
                }
            }
        });
    }

});

(function(window, document) {
  function init() {
    window.NotifyWidget({
      siteId: '131',
      container: '.NotifyWidget-banner'
    });
  }

  if (typeof window.NotifyWidget !== 'undefined') {
    init();
  } else {
    const script = document.createElement('script');
    script.src = '/assets/js/notifyWidget.min.js';
    script.async = true;
    script.onload = function () {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
})(window, document);
